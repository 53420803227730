<template>
  <div class="card full-page">
    <div class="card-body inner-scroll">
      <div v-show="step === 'select_plan'" class="select-plan-wrapper">
        <h2 class="info-title">
          {{ $t('Choose the plan that’s right for your business') }}
        </h2>
        <p v-show="showTrialInfo" class="info-content" v-html="$t('free_trial_info')"></p>
        <div class="plans-wrapper">
          <div
            class="plan-card"
            :class="{ 'active-plan': plan === 'plan_basic' }"
            @click="plan = 'plan_basic'"
          >
            <p class="plan-title">{{ $t('plan_basic') }}</p>
            <ul class="plan-features">
              <li>{{ $t('basic_plan_feature_1') }}</li>
              <li>{{ $t('basic_plan_feature_2') }}</li>
              <li>{{ $t('basic_plan_feature_3') }}</li>
            </ul>
            <span class="non-discounted-price"
              >{{ paymentPlans.plan_basic_yearly.non_discounted_price }} €
            </span>
            <p class="plan-price">
              en {{ paymentPlans.plan_basic_yearly.price }} €
              <span class="plan-period"> {{ $t('plan_price') }} {{ $t('+IVA') }} </span>
            </p>
          </div>
          <div
            class="plan-card"
            :class="{ 'active-plan': plan === 'plan_pro' }"
            @click="plan = 'plan_pro'"
          >
            <p class="plan-title">{{ $t('plan_pro') }}</p>
            <ul class="plan-features">
              <li>{{ $t('pro_plan_feature_1') }}</li>
              <li>{{ $t('pro_plan_feature_2') }}</li>
              <li>{{ $t('pro_plan_feature_3') }}</li>
              <li>{{ $t('pro_plan_feature_4') }}</li>
              <li>{{ $t('pro_plan_feature_5') }}</li>
              <li>{{ $t('pro_plan_feature_6') }}</li>
              <li>{{ $t('pro_plan_feature_7') }}</li>
              <li>{{ $t('pro_plan_feature_8') }}</li>
            </ul>
            <span class="non-discounted-price"
              >{{ paymentPlans.plan_pro_yearly.non_discounted_price }} €
            </span>
            <p class="plan-price">
              {{ paymentPlans.plan_pro_yearly.price }} €
              <span class="plan-period"> {{ $t('plan_price') }} {{ $t('+IVA') }} </span>
            </p>
          </div>
        </div>
        <p>{{ $t('reduce_price_desc') }}</p>
        <b-button
          variant="secondary"
          size="lg"
          class="confirm-button"
          @click="selectPlan"
          :disabled="$store.state.auth.user.plan === plan || isLoading"
        >
          <template v-if="isLoading">
            <b-spinner small />
          </template>
          <template v-else>
            {{ $t('Select') }}
          </template>
        </b-button>
        <div class="need-help">
          <div>
            <p class="need-help-title">{{ $t('Need help?') }}</p>
            <p class="need-help-content">
              {{
                $t(
                  'Having trouble deciding which plan is right for you? Set up a demo call, and we can help.'
                )
              }}
            </p>
          </div>
          <a
            class="btn btn-outline-secondary need-help-button"
            :href="scheduleCallLink"
            target="_blank"
            rel="noopener noreferrer"
            >{{ $t('Schedule a call') }}</a
          >
        </div>
      </div>
      <div v-show="step === 'banking_details'" class="banking-details-wrapper">
        <p
          v-if="!showTrialInfo"
          class="info-content"
          v-html="
            $t('upgrade_plan_summary', {
              amount: paymentPlans[`${plan}_${period}`].price,
              period: `${$t('year')}`,
            })
          "
        ></p>
        <p
          v-else
          class="info-content"
          v-html="
            $t('upgrade_plan_summary_with_trial', {
              amount: paymentPlans[`${plan}_${period}`].price,
              period: `${$t('year')}`,
              trialEndDate,
            })
          "
        ></p>
        <div class="stripe-form">
          <div id="payment-element"></div>
          <div v-show="showBillingDetails">
            <p class="stripe-billing-title">{{ $t('Billing details') }}</p>
            <div id="address-element"></div>
            <TaxIDField v-model="taxID" />
          </div>
          <p v-if="stripeError" class="stripe-error" role="alert">
            {{ stripeError }}
          </p>
          <b-button variant="secondary" size="lg" class="confirm-button" @click="paySubscription">
            <template v-if="isLoading">
              <b-spinner small />
            </template>
            <template v-else>
              {{ $t('Subscribe') }}
            </template>
          </b-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { BSpinner, BButton } from 'bootstrap-vue'
import { loadStripe } from '@stripe/stripe-js/pure'

import TaxIDField from '@/components/TaxIDField'
import { paymentPlans } from '@/core/config/payments'
import StripeService from '@/services/StripeService'

export default {
  name: 'Subscribe',
  components: {
    BSpinner,
    BButton,
    TaxIDField,
  },
  data() {
    return {
      StripeService: new StripeService(),
      plan: 'plan_basic',
      period: 'yearly',
      step: 'select_plan',
      stripeClient: undefined,
      stripeElements: undefined,
      stripeError: '',
      isLoading: false,
      paymentPlans: paymentPlans,
      scheduleCallLink: process.env.VUE_APP_SUBSCRIBE_FUNNEL_CALL_LINK,
      isCompany: true,
      taxID: { tax_id_type: '', tax_id: '' },
      showBillingDetails: false,
    }
  },
  computed: {
    showTrialInfo() {
      return (
        !this.$store.state.auth.user.has_used_demo &&
        this.$store.state.auth.user.plan === 'restricted'
      )
    },
    trialEndDate() {
      const FOURTEEN_DAYS = 14 * 24 * 60 * 60 * 1000
      const endDate = new Date(Date.now() + FOURTEEN_DAYS)
      return endDate.toLocaleDateString(this.$i18n.locale, {
        weekday: 'long',
        day: 'numeric',
        month: 'short',
        year: 'numeric',
      })
    },
  },
  methods: {
    async selectPlan() {
      this.isLoading = true

      try {
        const res = await this.StripeService.upgrade({
          plan: this.plan,
          period: this.period,
        })

        this.$posthog.capture('Select plan', {
          plan: this.plan,
          period: this.period,
          $set: {
            plan: this.plan,
            period: this.period,
          },
        })

        this.stripeClient = await loadStripe(process.env.VUE_APP_STRIPE_PUBLIC_KEY)

        let clientSecret
        if (this.$store.state.auth.user.has_used_demo) {
          clientSecret = res.subscription.latest_invoice?.payment_intent?.client_secret
        } else {
          clientSecret = res.subscription.pending_setup_intent.client_secret
        }

        this.stripeElements = this.stripeClient.elements({
          clientSecret,
          fonts: [
            {
              cssSrc:
                'https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap',
            },
          ],
          locale: this.$i18n.locale,
          appearance: {
            theme: 'stripe',
            variables: {
              fontFamily: 'Poppins, sans-serif',
              fontSizeBase: '14px',
              colorPrimary: '#435993',
              colorBackground: '#fff',
              colorText: '#0E1A1A',
              colorDanger: '#FD8389',
              borderRadius: '4px',
            },
          },
        })
        const paymentElement = this.stripeElements.create('payment', {
          wallets: { applePay: 'never', googlePay: 'never' },
        })
        paymentElement.mount('#payment-element')

        const stripeCustomer = await this.StripeService.getCustomer()
        if (!stripeCustomer.address && stripeCustomer.tax_ids.total_count === 0) {
          this.showBillingDetails = true

          const addressElement = this.stripeElements.create('address', {
            mode: 'billing',
            allowedCountries: ['es'],
            defaultValues: {
              name: this.$store.state.auth.user.business_name,
            },
            display: { name: 'organization' },
          })
          addressElement.mount('#address-element')
        }

        this.isLoading = false
        this.step = 'banking_details'
      } catch (error) {
        this.isLoading = false

        console.error(error)
      }
    },
    async paySubscription() {
      this.isLoading = true

      try {
        const queryParams = new URLSearchParams({
          plan: this.plan,
          period: this.period,
        })
        const return_url = `${process.env.VUE_APP_BASE_URL}/thanks?${queryParams.toString()}`

        if (this.showBillingDetails) {
          const addressElement = this.stripeElements.getElement('address')
          const { complete, value } = await addressElement.getValue()

          if (complete && this.taxID) {
            await this.StripeService.updateBillingDetails({
              name: value.name,
              ...value.address,
              ...this.taxID,
            })
            this.$posthog.capture('Add billing details')
          }
        }

        let stripePaymentResult
        if (this.$store.state.auth.user.has_used_demo) {
          stripePaymentResult = await this.stripeClient.confirmPayment({
            elements: this.stripeElements,
            confirmParams: {
              return_url,
            },
          })
        } else {
          stripePaymentResult = await this.stripeClient.confirmSetup({
            elements: this.stripeElements,
            confirmParams: {
              return_url,
            },
          })
        }

        if (stripePaymentResult.error) {
          this.stripeError = stripePaymentResult.error.message
          console.error('error', stripePaymentResult.error.message)
        }

        this.isLoading = false
      } catch (error) {
        this.isLoading = false

        console.error(error)
      }
    },
  },
  created() {
    this.plan = this.$store.state.auth.user.plan === 'plan_basic' ? 'plan_pro' : 'plan_basic'
  },
}
</script>

<style scoped>
.select-plan-wrapper {
  display: flex;
  flex-direction: column;
  gap: 16px;
  height: 100%;
  margin: auto;
}

.info-title {
  margin: 0;
  font-size: 1.5rem;
}

.plans-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 16px;
  width: 100%;
}

.plan-card.active-plan {
  border-color: #6bc4c5;
  background-color: #6bc4c5;
  color: #fff;
}

.plan-card {
  padding: 16px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  border-width: 1px;
  border-style: solid;
  border-color: #c9c9c9;
  background-color: #fff;
  cursor: pointer;
  max-width: 420px;
}

.plan-title {
  font-size: 1.5rem;
}
.plan-features {
  flex: 1;
  margin-bottom: 24px;
}

.plan-price {
  text-align: center;
  font-size: 2.5rem;
}

.plan-period {
  font-size: 0.5em;
}

.non-discounted-price {
  text-align: center;
  text-decoration: line-through;
  font-size: 18px;
  margin-right: 48px;
  margin-bottom: 4px;
}

.need-help {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 12px;
  margin-top: 32px;
  margin-bottom: 32px;
  padding-bottom: 32px;
}

.need-help-title {
  font-size: 1.5rem;
}

.need-help-content {
  margin: 0;
}

.need-help-button {
  max-height: fit-content;
  align-self: flex-end;
  width: 100%;
  color: #4b64a5;
}

.confirm-button {
  width: 100%;
}

::v-deep .spinner-border {
  vertical-align: middle;
}

.banking-details-wrapper {
  margin: auto;
}

.info-content {
  font-size: 16px;
  line-height: 1.5;
  margin: 0;
  margin-bottom: 16px;
  text-align: start;
}

.stripe-form {
  width: 100%;
  display: flex;
  flex-direction: column;
}

.stripe-billing-title {
  margin: 16px 0;
}

#address-element {
  margin-bottom: 12px;
}

@media screen and (min-width: 768px) {
  .info-title {
    font-size: 2rem;
  }

  .plans-wrapper {
    flex-direction: row;
    gap: 48px;
  }

  .plans-wrapper {
    flex-direction: row;
    gap: 48px;
  }

  .plan-card {
    min-width: 420px;
  }

  .need-help {
    flex-direction: row;
  }

  .need-help-button {
    max-width: fit-content;
  }

  .confirm-button {
    width: fit-content;
    min-width: 160px;
    margin: 24px auto auto auto;
  }
}

@media screen and (min-width: 1550px) {
  .banking-details-wrapper,
  .select-plan-wrapper {
    max-width: 1280px;
  }
}
</style>
